<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('SelectPrivacy')"
    content-class="pl-0 pr-0 pb-0"
    v-on:submit="updatePrivacy"
  >
    <template v-slot:content>
      <v-radio-group
        v-model="privacy"
        class="mt-0 pt-0 pb-0"
        :disabled="is_saving"
      >
        <v-list two-line class="mt-0 pt-0 pb-0">
          <v-list-item
            v-for="item in items"
            :key="item.value"
            @click="rowClick(item.value)"
            v-bind:class="{ 'global-list-item-active': privacy == item.value }"
          >
            <v-list-item-avatar class="grey lighten-2 text-center">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                class="global-list-header"
              ></v-list-item-title>
              <div class="global-list-desc">{{ item.subtitle }}</div>
            </v-list-item-content>

            <v-list-item-action>
              <v-radio :value="item.value"></v-radio>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-radio-group>
      <error-box :error="error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "SelectPrivacy": "Select privacy",
      "Public": "Public",
      "PublicDesc": "Anyone on or off Babiry",
      "Members": "Members",
      "MembersDesc": "All logged in users",
      "OnlyMe": "Only me",
      "OnlyMe": "Only me",
      "AlbumUpdated": "Album updated!",
      "MethodNotFound": "Method not found! Refresh the page and try again.",
      "PostUpdated": "Post updated!"
    },
    "sv": {
      "SelectPrivacy": "Välj sekretess",
      "Public": "Offentlig",
      "PublicDesc": "Alla på och utanför Barndagboken",
      "Members": "Medlemmar",
      "MembersDesc": "Enbart inloggade användare",
      "OnlyMe": "Bara jag",
      "OnlyMe": "Bara jag",
      "AlbumUpdated": "Albumet uppdaterades!",
      "MethodNotFound": "Anropet kunde inte genomföras. Vänligen uppdatera sidan och försök igen.",
      "PostUpdated": "Inlägget uppdaterades!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "diary_privacy_dialog",
  computed: {
    ...mapState({}),
  },
  components: {},
  data: () => ({
    is_open: false,
    error: null,
    is_saving: false,
    items: [],

    album: null,
    post: null,

    privacy: 1,
  }),
  created: function () {},
  methods: {
    ...mapActions({
      updateAlbumPrivacyDb: "diary/albums/updateAlbumPrivacy",
      updateBlogPostPrivacyDb: "diary/news/updatePrivacy",
    }),
    setPrivacyList() {
      this.items = [
        {
          title: this.$t("Public"),
          subtitle: this.$t("PublicDesc"),
          icon: "$vuetify.icons.fasGlobeEurope",
          value: 1,
        },
        {
          title: this.$t("Members"),
          subtitle: this.$t("MembersDesc"),
          icon: "$vuetify.icons.fasUsers",
          value: 2,
        },
        {
          title: this.$t("OnlyMe"),
          subtitle: this.$t("OnlyMe"),
          icon: "$vuetify.icons.fasLock",
          value: 0,
        },
      ];
    },
    rowClick(value) {
      this.privacy = value;
    },
    start() {
      this.setPrivacyList();

      this.is_saving = false;
      this.error = null;
      this.is_open = true;
    },
    openAlbum(album) {
      this.start();
      this.album = { ...album };
      this.privacy = this.album.privacy;
    },
    openBlogPost(post) {
      this.start();
      this.post = { ...post };
      this.privacy = this.post.privacy;
    },

    updatePrivacy() {
      var self = this;
      self.error = null;
      self.is_saving = true;

      if (self.album) {
        self.updateAlbumPrivacy();
      } else if (self.post) {
        self.updateBlogPostPrivacy();
      } else {
        self.$errorNort(self.$t("MethodNotFound"));
      }
    },

    updateAlbumPrivacy() {
      var self = this;
      self
        .updateAlbumPrivacyDb({ id: self.album.id, privacy: self.privacy })
        .then(function () {
          self.is_saving = false;
          self.is_open = false;
          self.$emit("updated", self.album);
          self.$successNoty(self.$t("AlbumUpdated"));
        })
        .catch(function (error) {
          self.error = error;
          self.is_saving = false;
        });
    },
    updateBlogPostPrivacy() {
      var self = this;
      self
        .updateBlogPostPrivacyDb({ id: self.post.id, privacy: self.privacy })
        .then(function () {
          self.is_saving = false;
          self.is_open = false;
          self.post.privacy = self.privacy;
          self.$emit("updated", self.post);
          self.$successNoty(self.$t("PostUpdated"));
        })
        .catch(function (error) {
          self.error = error;
          self.is_saving = false;
        });
    },
  },
  watch: {},
};
</script>